import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';

const MatchItem = ({ match }) => {
  const navigate = useNavigate();

  const changeDateFormat = (originalDate) => {
    const parsedDate = moment(originalDate, 'MMMM DD, YYYY h:mm:ss A');
    const newDate = parsedDate.format('MMM DD');
    return newDate;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: '#2B2B3D',
        borderRadius: '12px',
        color: 'white',
        overflow: 'hidden',
        alignItems: 'center',
        mb: 1.5,
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/match/${match.matchId}`)}
    >
      <Box
        sx={{
          bgcolor: '#222232',
          display: 'flex',
          flexDirection: 'column',
          gap: 0,
          py: 0.5,
          px: { xs: 2, md: 4 },
          flexShrink: 0,
          minWidth: 'max-content',
        }}
      >
        <Typography
          sx={{
            textAlign: 'left',
            color: '#46CDF7',
            textTransform: 'uppercase',
            fontSize: { sm: '18px', xs: '12px' },
            width: { sm: '75px', xs: '50px' },
            height: { sm: '25px', xs: '15px' },
            overflow: 'visible',
            whiteSpace: 'normal',
          }}
        >
          {match.matchType}
        </Typography>
        <Typography sx={{ textAlign: 'center', textTransform: 'uppercase', fontSize: { sm: '18px', xs: '12px' } }}>
          {changeDateFormat(match.matchDateTimeInfo)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 0.5, flex: 1, pl: { sm: 2, xs: 0.5 }, alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: { sm: '18px', xs: '12px' },
            textTransform: 'uppercase',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '45%',
          }}
        >
          {match?.matchData?.info?.[0]?.teams?.[0]?.teamName.length > 13
            ? match?.matchData?.info?.[0]?.teams?.[0]?.teamName.slice(0, 13) + '...'
            : match?.matchData?.info?.[0]?.teams?.[0]?.teamName}
        </Typography>
        <Typography
          sx={{ fontSize: { sm: '18px', xs: '12px' }, padding: { sm: 1, xs: 0 }, color: '#80808B', whiteSpace: 'nowrap' }}
        >
          vs
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: '18px', xs: '12px' },
            textTransform: 'uppercase',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '45%',
          }}
        >
          {match?.matchData?.info?.[0]?.teams?.[1]?.teamName.length > 13
            ? match?.matchData?.info?.[0]?.teams?.[1]?.teamName.slice(0, 13) + '...'
            : match?.matchData?.info?.[0]?.teams?.[1]?.teamName}
        </Typography>
      </Box>
    </Box>
  );
};

export default MatchItem;
