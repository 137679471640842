import React, { Component } from 'react'
import ErrorPage from '../../pages/default/ErrorPage'
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // any custom fallback UI
      return <ErrorPage />
    }

    return this.props.children
  }
}

export default ErrorBoundary
