import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const OverTracker = () => {
  const { currScore } = useSelector((state) => state.match);
  const [overData, setOverData] = useState([]);
  const [currentOver, setCurrentOver] = useState('0.0');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { showFirstInningScore } = currScore;

  useEffect(() => {
    const generateOverData = () => {
      const runsToColor = {
        0: '#FFFFFF', // White
        1: '#008000', // Green
        2: '#FFA500', // Orange
        3: '#FFC0CB', // Pink
        4: '#0000FF', // Blue
        6: '#FFFF00', // Yellow
        W: '#FF0000', // Red for Wicket
      };

      if (currScore?.currOverDetail?.balls) {
        const response = currScore.currOverDetail.balls;

        const updatedResponse = response
          .filter((ballData) => ballData.runs ?? ballData.run)
          .map((ballData) => {
            let runs = ballData.run;
            if (ballData.isWide) {
              runs = `${ballData.wideExtraRuns || ''}WD`;
            }
            if (ballData.isNoBall) {
              runs = `${ballData.noBallExtraRuns || ''}NB`;
            }
            if (ballData.isBye === 1) {
              runs += 'B';
            }
            return {
              ...ballData,
              color: ballData?.color || runsToColor[runs] || '#FFFFFF',
              runs: runs,
            };
          });

        setOverData(updatedResponse);
      }
    };

    if (showFirstInningScore) {
      setCurrentOver('0.0');
    }

    if (currScore?.currOverDetail?.over) {
      setCurrentOver(currScore.currOverDetail.over);
    }
    generateOverData();
  }, [currScore]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    if (currScore?.currOverDetail?.over) {
      setCurrentOver(currScore.currOverDetail.over);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const boxStyle = {
    width: '100%',
    height: '35px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 2px',
  };

  const innerBoxStyle = {
    width: isMobile ? '130%' : '130%',
    height: '31px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    gap: 0,
    alignItems: 'center',
  };

  const circleBoxStyle = {
    width: isMobile ? '28px' : '30px',
    height: isMobile ? '28px' : '30px',
    borderRadius: '50%',
    backgroundColor: 'white',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2px',
    marginLeft: '5px',
  };

  return (
    <Box
      sx={{
        marginTop: '0px',
        mb: 0,
        p: 0.5,
        height: '45px',
        bgcolor: 'black',
        border: '1px solid #ccc',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: '0px',
        overflowX: 'auto',
        '@media (min-width: 601px)': {
          overflowX: 'visible',
        },
        '@media (max-width: 600px)': {
          p: 0,
          maxWidth: '400px',
          width: '100%',
          height: '45px',
        },
      }}
    >
      <Box sx={{ p: 0.5 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            flexDirection: 'row',
            flexWrap: 'nowrap',
            maxWidth: '100%',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              height: '0px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'grey',
              borderRadius: '0px',
              height: '0px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'darkgrey',
              height: '0px',
            },
          }}
        >
          <Grid item>
            <div style={boxStyle}>
              <div
                style={{
                  ...innerBoxStyle,
                  height: '38px',                                           
                  flexDirection: 'column',
                  marginLeft: '5px',
                }}
              >
                <Typography sx={{ color: '#000000', fontSize: '10px', LineHeight: '10px', fontWeight: 400, Align: 'center' }}>
                  OVER
                </Typography>
                <Typography sx={{ color: '#000000', fontWeight: 600, fontSize: '12px', LineHeight: '12px', Align: 'center' }}>
                  {currentOver}
                </Typography>
              </div>
            </div>
          </Grid>
          {overData.map((ballData, index) => (
            <Grid item key={index}>
              <div style={{ ...circleBoxStyle, backgroundColor: ballData.color }}>
                <Typography sx={{ color: '#000000', fontWeight: 600, fontSize: '10px', LineHeight: '10px' }}>
                  {ballData.runs}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default OverTracker;
