import React from 'react'

const Table = ({ columns }) => {
  return (
    <div style={{ padding: '6px', margin: '18px', borderRadius: '10px', border: '1px solid #C8CDF9', background: '#FFFFFF', boxShadow: '0px 6px 16px 0px #14142B1C' }}>
      {columns.map((column, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '8px', borderBottom: index < columns.length - 1 ? '1px solid #E5E7EB' : 'none'}}>
          <div style={{ flex: 1, textAlign: 'left', color: '#64748B', fontSize: '10px', padding: '2px'}}>
            {column.label}
          </div>
          <div style={{ flex: 1.75, textAlign: 'left', color: '#0A0A0A', fontWeight: 600, fontSize: '10px', display: 'flex-start', alignItems: 'left', textTransform: 'uppercase', padding: '2px' }}>
            {column.imgUrl && (
              <img
                src={column.imgUrl}
                alt={column.label}
                style={{
                  maxWidth: '50px',
                  maxHeight: '50px',
                  marginRight: '8px',
                  verticalAlign: 'middle',
                }}
              />
            )}
            {column.value}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Table;