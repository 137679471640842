import Cookies from 'js-cookie';
import axios from 'axios';
import {
  setUser,
  setError,
  setLoading,
  setUserProfile,
  setIsAuthenticated,
  setNextAuthAction,
  toggleAuthWallOff,
  toggleAuthWallOn,
} from './auth.slice';
import api from '../../api/api';
export const signUp = (signupPayload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1.2.0/user/signup`, signupPayload);
      dispatch(setUser(response.data.data.newUser));
      if (response.data?.data?.accessToken) {
        Cookies.set('accessToken', response.data.data.accessToken, {
          httpOnly: false,
          secure: true,
          sameSite: 'Strict',
          expires: 1,
        });
        Cookies.set('refreshToken', response.data.data.refreshToken, {
          httpOnly: false,
          secure: true,
          sameSite: 'Strict',
          expires: 3,
        });
        dispatch(setIsAuthenticated(true));
        dispatch(toggleAuthWallOff());
      } else {
        dispatch(setIsAuthenticated(false));
      }
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    }
  };
};
export const login = (loginPayload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/login`, loginPayload);
      dispatch(setUser(response.data.data.user));
      if (response.data?.data?.user?.accessToken) {
        Cookies.set('accessToken', response.data.data.user?.accessToken, {
          httpOnly: false,
          secure: true,
          sameSite: 'Strict',
          expires: 1,
        });
        Cookies.set('refreshToken', response.data.data.user?.refreshToken, {
          httpOnly: false,
          secure: true,
          sameSite: 'Strict',
          expires: 1,
        });
        dispatch(setIsAuthenticated(true));
        dispatch(toggleAuthWallOff());
      } else {
        dispatch(setIsAuthenticated(false));
      }
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    }
  };
};
export const existingUser = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/existingUser`, payload);
      const nextStep = response.data.data.nextStep;
      dispatch(setNextAuthAction(nextStep));
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    }
  };
};
export const verifyAccessToken = () => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_BASE_URL}api/v0/user/verify-access-token`);
      if (response.data?.data) {
        dispatch(setIsAuthenticated(true));
        dispatch(toggleAuthWallOff());
      } else {
        dispatch(setIsAuthenticated(false));
        dispatch(toggleAuthWallOn());
      }
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    }
  };
};
export const logout = () => {
  return async (dispatch) => {
    try {
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      dispatch(setIsAuthenticated(false));
      dispatch(toggleAuthWallOff());
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    }
  };
};
export const sendEmail = (reqPayload) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${process.env.REACT_APP_API_BASE_URL}api/send-email`,reqPayload);
      // set something in redux
    } catch (error) {
      dispatch(setError(error?.response?.message || error?.message));
    }
  };
};