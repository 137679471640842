import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import api from './../api/api';

const useTournamentStats = (tournamentId) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [tournamentStats, setTournamentStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTournamentStats = async () => {
      setLoading(true);
      try {
        let response;
        if (isAuthenticated) {
          response = await api.get(`${process.env.REACT_APP_API_BASE_URL}api/v0/cricket/tournament/stats/${tournamentId}`, {
            params: {
              tmtLdBd: true,
            },
          });
        } else {
          response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/tournament/stats/${tournamentId}`, {
            params: {
              tmtLdBd: true,
            },
          });
        }
        setTournamentStats(response.data.data);
      } catch (error) {
        setError(error);
        console.error('Error fetching tournament stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentStats();
  }, [tournamentId, isAuthenticated]);

  return { tournamentStats, loading, error };
};

export default useTournamentStats;
