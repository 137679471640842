
import React from 'react';
import { Box, Button } from '@mui/material';
import { keyframes } from '@emotion/react';
import { useSelector } from 'react-redux';

const fadeInOut = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const LiveScoringButton = () => {
  const { isLive, currScore } = useSelector((state) => state.match);
  const isMatchLive = isLive;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {isMatchLive && (
        <Button
          variant="contained"
          sx={{
            bgcolor: '#DF513D',
            fontSize: '0.7rem', 
            p: 0.5, 
            borderRadius: '30px',
            position: 'relative',
            animation: `${fadeInOut} 2s infinite`,
            minWidth: '73px', 
            height: '16px', 
          }}
        >
          <span
            style={{
              position: 'absolute',
              left: '4px',
              top: '50%',
              transform: 'translateY(-60%)',
              width: '5px',
              height: '5px',
              borderRadius: '50%',
              backgroundColor: '#FFFFFF',
            }}
          ></span>
          <span style={{ marginLeft: '10px', fontWeight: 500, fontSize: '8px', lineHeight: '16px'}}>LIVE SCORING</span>
        </Button>
      )}
    </Box>
  );
};

export default LiveScoringButton;
