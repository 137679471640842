import React from 'react';
import { Box, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import MainWidthWrapper from '../../components/MainWidthWrapper';

const Cookiespolicy = () => {
  const rows = [
    { column1: 'CHANNEL', column2: 'Google', column3: 'Marketing', column4: '2 years' },
    { column1: '_ga', column2: 'Google', column3: 'Marketing', column4: '2 years' },
    { column1: '_ga_HHFXB6GP00', column2: 'Google', column3: 'Marketing', column4: '2 years' },
    { column1: 'accessToken', column2: 'Gully Six', column3: 'Marketing', column4: '3 days' },
    { column1: 'nekoTssel-PTO', column2: 'Google', column3: 'Marketing', column4: '3 days' },
    { column1: 'refreshToken', column2: 'Gullysix', column3: 'Marketing', column4: '3 days' },
    { column1: 'asld', column2: 'OTPLESS', column3: 'Technical', column4: '7 days' },
    { column1: 'channelAuthToken', column2: 'OTPLESS', column3: 'Technical', column4: '7 days' },
    { column1: 'inld', column2: 'OTPLESS', column3: 'Technical', column4: '7 days' },
    { column1: 'iot', column2: 'OTPLESS', column3: 'Technical', column4: '7 days' },
    { column1: 'state', column2: 'OTPLESS', column3: 'Technical', column4: '7 days' },
    { column1: 'uid', column2: 'OTPLESS', column3: 'Technical', column4: '7 days' },
  ];
  return (
    <>
      <MainWidthWrapper>
        <div className="w-full px-10 py-12">
          <div className="max-w-8xl text-left">
            <p className="text-left text-lg font-bold underline">Cookies and similar tracking technology</p>

            <p className="text-sm mt-2">
              We may collect personal information using cookies and similar tracking technologies. Cookies are small data text
              files and can be stored on your computer's hard drive (if your Web browser permits).
            </p>

            <p className="text-sm mt-2">
              We use technical, functional, analytic (marketing), personalization, and advertising cookies.
            </p>

            <p className="text-sm mt-2">
              Technical and functional cookies do not require your consent. These types of cookies are only placed and used in our
              own Services, for example on our website, and do not extend past such use. These cookies facilitate the functional
              parts of our Services, such as the contents of your shopping cart, and as such are necessary to provide our
              functional Services to you.
            </p>

            <p className="text-sm mt-2">
              We require your consent for our use of most analytic, personalization, and advertising cookies. These cookies are
              used to create a profile of you, so we can improve your user experience and facilitate marketing content customized
              to your profile. Examples of these cookies are:
            </p>

            <ul className="list-disc pl-5 text-sm mt-2">
              <li>
                Help us recognize you as a previous visitor and save any preferences that may have been set while your browser was
                visiting our site.
              </li>
              <li>Help us customize the content and advertisements provided to you on this website.</li>
              <li>
                Help measure and research the effectiveness of website features and offerings, advertisements, and email
                communications (by determining which emails you open and act upon).
              </li>
            </ul>

            <p className="text-sm mt-2">
              The cookies used by us and their respective functions and retention periods are as follows:
            </p>

            <div className="overflow-x-auto mt-4">
              <table className="min-w-full bg-white border border-gray-300">
                <thead>
                  <tr>
                    <th className="border border-gray-300 p-2">Cookie</th>
                    <th className="border border-gray-300 p-2">Placed by</th>
                    <th className="border border-gray-300 p-2">Category</th>
                    <th className="border border-gray-300 p-2">Retention Period</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 p-2">{row.column1}</td>
                      <td className="border border-gray-300 p-2">{row.column2}</td>
                      <td className="border border-gray-300 p-2">{row.column3}</td>
                      <td className="border border-gray-300 p-2">{row.column4}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <p className="text-sm mt-4">
              Some cookies are set and collected by third parties, such as Google. We refer you to the information such third
              parties provide on cookies on their own websites.
            </p>

            <p className="text-sm mt-2">
              We will ask your consent for the cookies which require consent prior to placing them on your device. You can manage
              cookie settings through your browser.
            </p>

            <p className="text-sm mt-2">
              In general, we will use the personal information we collect from you only for the purposes described in this Privacy
              Policy or for purposes that we explain to you at the time we collect your personal information.
            </p>

            <p className="text-base font-bold underline mt-4">Changes to the cookie statement</p>

            <p className="text-sm mt-2">
              This cookie statement is subject to change, so please consult this page regularly for the latest version.
            </p>

            <p className="text-base font-bold underline mt-4">Contact Us</p>

            <p className="text-sm mt-2">
              If you have any questions or grievances about this Cookie Statement, please contact us by sending an email to:{' '}
              <a href="mailto:compliance@myysports.com" className="text-blue-500 underline">
                compliance@myysports.com
              </a>
            </p>
          </div>
        </div>
      </MainWidthWrapper>
    </>
  );
};

export default Cookiespolicy;
