import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { existingUser, signUp, login } from '../../redux/auth/auth.action';
import { getUserIdentifier } from '../../helpers/userUuid';
import { setOtplessUser } from '../../redux/auth/auth.slice';
import { toggleAuthWallOff } from '../../redux/auth/auth.slice';

const OtpLessLoginUI = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, nextAuthAction, otplessUser } = useSelector((state) => state.auth);
  const buildSignupPayload = (userInfo) => {
    if (userInfo) {
      const { identityType, identityValue, channel, methods, name, verified, picture } = userInfo.identities[0];
      const method = methods[methods.length - 1]; // apple, google, whatsapp, etc
      let idField = null;
      switch (identityType) {
        case 'EMAIL':
          idField = 'email';
          break;
        case 'Mobile':
          idField = 'phone';
          break;
        default:
          console.log('Unknown identity type');
          break;
      }
      const sourceMeta = {
        method,
        channel,
        idField,
      };
      const signupPayload = {
        newUser: {
          userName: '',
          password: '',
          role: 'user',
          name: {
            firstName: name,
            lastName: '',
          },
          mobileNo: {
            primary: idField === 'phone' ? identityValue : null,
            isPrimaryVerified: idField === 'phone' ? verified : false,
          },
          email: idField === 'email' ? identityValue : null,
          profilePicture: picture ? picture : undefined,
        },
        fcmDeviceToken: '',
        signupSource: {
          serviceName: 'OTPLESS',
          sourceMeta,
        },
        platform: 'web',
        deviceId: getUserIdentifier(),
      };
      return signupPayload;
    }
    return null;
  };
  const buildLoginPayload = (userInfo) => {
    if (userInfo) {
      const { identityType, identityValue, channel, methods, name, verified, picture } = userInfo.identities[0];
      const LoginPayload = {
        identity: identityValue,
        password: '',
        fcmDeviceToken: '',
        deviceId: getUserIdentifier(),
        platform: 'web',
        claimAssist: false,
      };
      return LoginPayload;
    }
    return null;
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'otpless-sdk';
    script.type = 'text/javascript';
    script.src = 'https://otpless.com/v2/auth.js';
    script.setAttribute('data-appid', process.env.REACT_APP_OTPLESS_APP_ID);
    document.head.appendChild(script);

    window.otpless = (otplessUser) => {
      dispatch(setOtplessUser(otplessUser));
    };
  }, []);
  useEffect(() => {
    if (otplessUser) {
      const { identityType, identityValue, channel, methods, name, verified, picture } = otplessUser.identities[0];
      const exisitingUserPayload = {
        userIdentity: identityValue,
        claimAssist: false,
        isIdVerified: true,
        idVerificationSrc: methods[0],
      };
      dispatch(existingUser(exisitingUserPayload));
    }
  }, [otplessUser, dispatch]);
  useEffect(() => {
    if (nextAuthAction?.action && otplessUser) {
      const action = nextAuthAction?.action?.toUpperCase();
      switch (action) {
        case 'LOGIN': {
          const loginPayload = buildLoginPayload(otplessUser);
          dispatch(login(loginPayload));
          dispatch(toggleAuthWallOff());
          break;
        }

        case 'SIGNUP': {
          const signuUpPayload = buildSignupPayload(otplessUser);
          dispatch(signUp(signuUpPayload));
          dispatch(toggleAuthWallOff());

          break;
        }

        default:
          console.log('Unknown action');
          break;
      }
    }
  }, [nextAuthAction, dispatch]);
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div id="otpless-login-page"></div>
      </div>
    </>
  );
};

export default OtpLessLoginUI;
