import { Box, Typography } from "@mui/material";
import React from "react";

const MainHeading = ({ color = "black", bgColor = "white", fSize = "14px", alignment = "center", label, caps = true }) => {
  return (
    <Box sx={{ bgcolor: bgColor, color: color }}>
      <Typography variant="h4" sx={{ fontSize: { xs: '0.85rem', md: '1.15rem' }, textAlign: alignment, textTransform: caps ? "uppercase" : "none" }}><b>
        {label}
        </b>
      </Typography>
    </Box>
  );
};

export default MainHeading;
