import React from 'react'

const ErrorPage = () => {
  return (
    <div>
      <h1>An error has occurred</h1>
      <p>Something went wrong. Please try again later.</p>
    </div>
  )
}

export default ErrorPage
