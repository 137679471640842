import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import gullyLogo from '../../assets/gully-logo.svg';
import HeadingWithLines from '../../components/Headings/HeadingWithLines';
import MainWidthWrapper from '../../components/MainWidthWrapper';
import MatchItem from '../../components/MatchItem';
import { fetchAllMatches } from '../../redux/match/match.action';
import { clearGetAllMatches } from '../../redux/match/match.slice';
import CenteredLinks from '../../components/CenteredLinks';
import CalendarView from '../../components/CalenderView';
import moment from 'moment';

const MatchList = () => {
  const selectedDate = useSelector((state) => state.match.date);

  const dispatch = useDispatch();
  const matchData = useSelector((state) => state.match, shallowEqual);
  const { pageSize, totalPages } = matchData;
  const getAllMatches = useSelector((state) => state.match.getAllMatches);
  const [currentPage, setPage] = useState(1);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const links = [
    { text: 'About Us', url: '/about-us' },
    { text: 'Privacy Policy', url: '/privacy-policy' },
    { text: 'Terms of Service', url: '/terms-of-service' },
  ];

  useEffect(() => {
    if (currentPage <= totalPages) {
      if (selectedDate === null) {
        dispatch(fetchAllMatches(currentPage, pageSize));
      } else {
        dispatch(fetchAllMatches(currentPage, pageSize, selectedDate));
      }
    }
  }, [dispatch, currentPage, pageSize, selectedDate, totalPages]);

  const handleInfinityScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleInfinityScroll);

    return () => window.removeEventListener('scroll', handleInfinityScroll);
  }, [handleInfinityScroll]);

  const handleCalendarDateSelect = (date) => {
    setPage(1);
    dispatch(fetchAllMatches(1, pageSize, date));
  };

  useEffect(() => {
    return () => {
      dispatch(clearGetAllMatches());
    };
  }, [dispatch]);

  return (
    <MainWidthWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'center', pt: 5 }}>
        <Box component={'img'} src={gullyLogo} alt={'gully logo here'} />
      </Box>
      <Box>
        <CenteredLinks links={links} />
      </Box>
      <Box sx={{ px: 3 }}>
        <HeadingWithLines
          label={'Scheduled Matches'}
          lineWidth={'0.5px'}
          Icon={<CalendarMonthIcon sx={{ fontSize: '14px', mr: 0.5 }} />}
        />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '100%' }}>
            <CalendarView setCurrentPage={setPage} onSelectDate={handleCalendarDateSelect} sx={{ width: '100%' }} />
          </Box>
        </Box>
        <Box sx={{ py: 2 }}>
          {getAllMatches &&
            getAllMatches.map((match) => (
              <Box key={match._id}>
                <MatchItem match={match} />
              </Box>
            ))}
        </Box>
      </Box>
    </MainWidthWrapper>
  );
};

export default MatchList;
