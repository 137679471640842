import React from 'react';
import { Box, Card, Container, Typography, Divider, useMediaQuery } from '@mui/material';
import GoldBadge from '../../assets/Badge.svg';

const commonTypographyStyle = {
  textTransform: 'uppercase',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
  textAlign: 'left',
  color: '#0A0A0A',
};

const teamStyle = {
  fontSize: '11px',
  fontWeight: 500,
  lineHeight: '11px',
  textAlign: 'left',
  color: '#64748B',
  padding: '2px 4px',
  borderRadius: '4px',
};

const PlayerStyle = {
  textTransform: 'uppercase',
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '16px',
  textAlign: 'left',
  color: '#0A0A0A',
  padding: '2px 4px',
  borderRadius: '4px',
};

const profileContainerStyle = {
  width: '88px',
  height: '95px',
  padding: '17px 16px 17px 16px',
  gap: '4px',
  borderRadius: '0px 0px 46px 0px',
  background: '#978FED',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const profilePhotoStyle = {
  width: '56px',
  height: '56px',
  gap: '0px',
  borderRadius: '50%',
  background: '#FAFAFA',
  border: '1.5px solid #FCFBFE',
};

const cardStyle = {
  height: '96px',
  gap: '0px',
  borderRadius: '10px',
  background: '#FFFFFF',
  boxShadow: '0px 6px 16px 0px #14142B1C',
};

const flexContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 1,
  mt: 1,
};

const borderBottomStyle = {
  pb: 1,
  borderBottom: '2px solid black',
};

const centralizedStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const goldBadgeStyle = {
  width: 32,
  height: 32,
};

const customStyle = {
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '11px',
  textAlign: 'center',
  color: '#64748B',
  padding: '2px 4px',
  borderRadius: '4px',
};

const pointsBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '72px',
  height: '22px',
  padding: '3px 9px',
  margin: '6px',
  gap: '8px',
  borderRadius: '6px',
  border: '0.75px solid #F4A8A8',
  background: '#FFF5F5',
};

const TopPerformerCard = ({
  badgeIcon,
  badgeText,
  playerName,
  teamName,
  runs,
  strikeRate,
  fours,
  sixes,
  Points,
  profilePhoto,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const roundToOneDecimal = (value) => {
    return Number.isInteger(value) ? value : value.toFixed(1);
  };

  const roundToThousand = (value) => {
    if (value < 1) {
      return Math.floor(value * 1000);
    } else {
      return Math.floor(value);
    }
  };

  const truncatedTeamName = teamName.length > 15 ? `${teamName.slice(0, 15)}...` : teamName;

  return (
    <Card sx={{ ...cardStyle }}>
      <Container maxWidth="md" sx={{ display: 'flex', p: 0 }}>
        <Box sx={profileContainerStyle}>
          <img src={profilePhoto} alt={playerName} style={profilePhotoStyle} />
        </Box>
        <Box sx={{ flex: 1, py: 0.5 }}>
          <Box
            sx={{
              ...flexContainerStyle,
              flexDirection: isMobile ? 'row' : 'row',
              justifyContent: 'space-between',
              mt: isMobile ? '10px' : '14px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                textAlign: isMobile ? 'center' : 'left',
              }}
            >
              <Typography
                sx={{
                  ...PlayerStyle,
                  fontSize: isMobile ? '12px' : '14px',
                  textTransform: 'uppercase',
                }}
              >
                {playerName}
              </Typography>
              <Typography
                sx={{
                  ...teamStyle,
                  fontSize: isMobile ? '10px' : '12px',
                  textTransform: 'uppercase',
                  textAlign: 'left',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }}
              >
                {truncatedTeamName}
              </Typography>
            </Box>
            <Box sx={pointsBoxStyle}>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  fontSize: '12px',
                  lineHeight: '16px',
                  textAlign: 'center',
                  color: '#0A0A0A',
                }}
              >
                {roundToThousand(Points)}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '10px',
                  lineHeight: '11px',
                  textAlign: 'center',
                  color: '#0A0A0A',
                }}
              >
                Points
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ bgcolor: '#E2E8F0', height: '0.75px', my: 0.25, mx: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', my: 1.25 }}>
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{runs}</Typography>
              <Typography sx={customStyle}>Runs Scored</Typography>
            </Box>
            <Box
              sx={{
                width: '0.75px',
                bgcolor: '#E2E8F0',
              }}
            />
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{strikeRate}</Typography>
              <Typography sx={customStyle}>Strike Rate</Typography>
            </Box>
            <Box
              sx={{
                width: '0.75px',
                bgcolor: '#E2E8F0',
              }}
            />
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{fours}</Typography>
              <Typography sx={customStyle}>4&apos;s</Typography>
            </Box>
            <Box
              sx={{
                width: '0.75px',
                bgcolor: '#E2E8F0',
              }}
            />
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{sixes}</Typography>
              <Typography sx={customStyle}>6&apos;s</Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Card>
  );
};

const BowlerCard = ({
  badgeIcon,
  badgeText,
  playerName,
  teamName,
  strikeRate,
  over,
  Wickets,
  dotballs,
  Points,
  profilePhoto,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const roundToOneDecimal = (value) => {
    return Number.isInteger(value) ? value : value.toFixed(1);
  };

  const roundToThousand = (value) => {
    if (value < 1) {
      return Math.floor(value * 1000);
    } else {
      return Math.floor(value);
    }
  };

  const truncatedTeamName = teamName.length > 15 ? `${teamName.slice(0, 15)}...` : teamName;

  return (
    <Card sx={{ ...cardStyle }}>
      <Container maxWidth="md" sx={{ display: 'flex', p: 0 }}>
        <Box sx={profileContainerStyle}>
          <img src={profilePhoto} alt={playerName} style={profilePhotoStyle} />
        </Box>
        <Box sx={{ flex: 1, py: 0.5 }}>
          <Box
            sx={{
              ...flexContainerStyle,
              flexDirection: isMobile ? 'row' : 'row',
              justifyContent: 'space-between',
              mt: isMobile ? '10px' : '14px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                textAlign: isMobile ? 'center' : 'left',
              }}
            >
              <Typography
                sx={{
                  ...PlayerStyle,
                  fontSize: isMobile ? '12px' : '14px',
                  textTransform: 'uppercase',
                }}
              >
                {playerName}
              </Typography>
              <Typography
                sx={{
                  ...teamStyle,
                  fontSize: isMobile ? '10px' : '12px',
                  textTransform: 'uppercase',
                  textAlign: 'left',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }}
              >
                {truncatedTeamName}
              </Typography>
            </Box>
            <Box sx={pointsBoxStyle}>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  fontSize: '12px',
                  lineHeight: '16px',
                  textAlign: 'center',
                  color: '#0A0A0A',
                }}
              >
                {roundToThousand(Points)}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '10px',
                  lineHeight: '11px',
                  textAlign: 'center',
                  color: '#0A0A0A',
                }}
              >
                Points
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ bgcolor: '#E2E8F0', height: '0.75px', my: 0.25, mx: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', my: 1.25 }}>
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{Wickets}</Typography>
              <Typography sx={customStyle}>Wickets</Typography>
            </Box>
            <Box
              sx={{
                width: '0.75px',
                bgcolor: '#E2E8F0',
              }}
            />
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{over}</Typography>
              <Typography sx={customStyle}>Overs</Typography>
            </Box>
            <Box
              sx={{
                width: '0.75px',
                bgcolor: '#E2E8F0',
              }}
            />
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{roundToOneDecimal(strikeRate)}</Typography>
              <Typography sx={customStyle}>Strike Rate</Typography>
            </Box>
            <Box
              sx={{
                width: '0.75px',
                bgcolor: '#E2E8F0',
              }}
            />
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{dotballs}</Typography>
              <Typography sx={customStyle}>Dot Balls</Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Card>
  );
};

const MVPCard = ({ badgeIcon, badgeText, playerName, teamName, Points, runs, strikeRate, wickets, economy, profilePhoto }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const roundToOneDecimal = (value) => {
    return Number.isInteger(value) ? value : value.toFixed(1);
  };

  const roundToThousand = (value) => {
    if (value < 1) {
      return Math.floor(value * 1000);
    } else {
      return Math.floor(value);
    }
  };

  const truncatedTeamName = teamName.length > 15 ? `${teamName.slice(0, 15)}...` : teamName;

  return (
    <Card sx={{ ...cardStyle }}>
      <Container maxWidth="md" sx={{ display: 'flex', p: 0 }}>
        <Box sx={profileContainerStyle}>
          <img src={profilePhoto} alt={playerName} style={profilePhotoStyle} />
        </Box>
        <Box sx={{ flex: 1, py: 0.5 }}>
          <Box
            sx={{
              ...flexContainerStyle,
              flexDirection: isMobile ? 'row' : 'row',
              justifyContent: 'space-between',
              mt: isMobile ? '10px' : '14px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                textAlign: isMobile ? 'center' : 'left',
              }}
            >
              <Typography
                sx={{
                  ...PlayerStyle,
                  fontSize: isMobile ? '12px' : '14px',
                  textTransform: 'uppercase',
                }}
              >
                {playerName}
              </Typography>
              <Typography
                sx={{
                  ...teamStyle,
                  fontSize: isMobile ? '10px' : '12px',
                  textTransform: 'uppercase',
                  textAlign: 'left',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }}
              >
                {truncatedTeamName}
              </Typography>
            </Box>
            <Box sx={pointsBoxStyle}>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  fontSize: '12px',
                  lineHeight: '16px',
                  textAlign: 'center',
                  color: '#0A0A0A',
                }}
              >
                {roundToThousand(Points)}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '10px',
                  lineHeight: '11px',
                  textAlign: 'center',
                  color: '#0A0A0A',
                }}
              >
                Points
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ bgcolor: '#E2E8F0', height: '0.75px', my: 0.25, mx: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', my: 1.25 }}>
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{runs}</Typography>
              <Typography sx={customStyle}>Runs Scored</Typography>
            </Box>
            <Box
              sx={{
                width: '0.75px',
                bgcolor: '#E2E8F0',
              }}
            />
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{roundToOneDecimal(strikeRate)}</Typography>
              <Typography sx={customStyle}>Strike Rate</Typography>
            </Box>
            <Box
              sx={{
                width: '0.75px',
                bgcolor: '#E2E8F0',
              }}
            />
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{wickets}</Typography>
              <Typography sx={customStyle}>Wickets</Typography>
            </Box>
            <Box
              sx={{
                width: '0.75px',
                bgcolor: '#E2E8F0',
              }}
            />
            <Box sx={centralizedStyle}>
              <Typography sx={commonTypographyStyle}>{economy}</Typography>
              <Typography sx={customStyle}>Economy</Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Card>
  );
};

export { TopPerformerCard, BowlerCard, MVPCard };
