import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './css/calenderview.css';
import { setSelectedDate, clearGetAllMatches } from '../redux/match/match.slice';

const CalendarView = ({ setCurrentPage, fetchMatchesAction, tournamentId }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedCardIndex, setSelectedCardIndex] = useState(3);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const dispatch = useDispatch();
  const calendarPickerRef = useRef(null);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('selectedDate');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const savedDate = sessionStorage.getItem('selectedDate');
    if (savedDate) {
      setCurrentDate(moment(savedDate, 'DD-MM-YYYY').toDate());
      setDateSelected(true);
    } else {
      setCurrentDate(new Date());
      setSelectedCardIndex(null);
    }
  }, []);

  useEffect(() => {
    const updateCalendarData = () => {
      const dayBeforeYesterday = moment(currentDate).subtract(2, 'days');
      const yesterday = moment(currentDate).subtract(1, 'days');
      const tomorrow = moment(currentDate).add(1, 'days');
      const dayAfterTomorrow = moment(currentDate).add(2, 'days');

      const currentDayOfWeek = moment(currentDate).format('ddd').toUpperCase();
      const dayBeforeYesterdayOfWeek = dayBeforeYesterday.format('ddd').toUpperCase();
      const yesterdayOfWeek = yesterday.format('ddd').toUpperCase();
      const tomorrowOfWeek = tomorrow.format('ddd').toUpperCase();
      const dayAfterTomorrowOfWeek = dayAfterTomorrow.format('ddd').toUpperCase();

      const newCalendarData = [
        {
          day: dayBeforeYesterdayOfWeek,
          date: formatDate(dayBeforeYesterday, true),
          dispatchDate: formatDate(dayBeforeYesterday),
        },
        {
          day: yesterdayOfWeek,
          date: dateSelected ? formatDate(yesterday, true) : 'Yesterday',
          dispatchDate: formatDate(yesterday),
        },
        {
          day: currentDayOfWeek,
          date: dateSelected ? formatDate(currentDate, true) : 'Today',
          dispatchDate: formatDate(currentDate),
        },
        {
          day: tomorrowOfWeek,
          date: dateSelected ? formatDate(tomorrow, true) : 'Tomorrow',
          dispatchDate: formatDate(tomorrow),
        },
        {
          day: dayAfterTomorrowOfWeek,
          date: formatDate(dayAfterTomorrow, true),
          dispatchDate: formatDate(dayAfterTomorrow),
        },
      ];

      setCalendarData(newCalendarData);
    };

    updateCalendarData();
  }, [currentDate, dateSelected]);

  const formatDate = (date, forDisplay = false) => {
    return forDisplay ? moment(date).format('MMM DD').toUpperCase() : moment(date).format('DD-MM-YYYY');
  };

  const handleDateChange = (date) => {
    dispatch(clearGetAllMatches());
    setCurrentPage(1);
    setCurrentDate(date);
    setCalendarOpen(false);
    setDateSelected(true);

    sessionStorage.removeItem('selectedCardIndex');
    sessionStorage.setItem('selectedDate', formatDate(date));

    const index = calendarData.findIndex((item) => item.dispatchDate === formatDate(date));
    if (index !== -1) {
      setSelectedCardIndex(index + 1);
    } else {
      setSelectedCardIndex(0);
    }

    dispatch(setSelectedDate(formatDate(date)));

    if (fetchMatchesAction && tournamentId) {
      dispatch(fetchMatchesAction(tournamentId));
    }
  };

  const handleCalendarCardClick = (date, index) => {
    setCurrentPage(1);
    dispatch(clearGetAllMatches());
    dispatch(setSelectedDate(date));
    setSelectedCardIndex(index);
    sessionStorage.setItem('selectedCardIndex', index);
    sessionStorage.setItem('selectedDate', date);
  };

  const handleAllCardClick = () => {
    setCurrentPage(1);
    dispatch(clearGetAllMatches());
    dispatch(setSelectedDate(null));
    setSelectedCardIndex(0);
    console.log(setSelectedDate, 'date');
    sessionStorage.setItem('selectedCardIndex', 0);
    sessionStorage.removeItem('selectedDate');

    if (fetchMatchesAction && tournamentId) {
      dispatch(fetchMatchesAction(tournamentId));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarPickerRef.current && !calendarPickerRef.current.contains(event.target)) {
        setCalendarOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const savedIndex = sessionStorage.getItem('selectedCardIndex');
    const savedDate = sessionStorage.getItem('selectedDate');
    if (savedDate) {
      const savedDateMoment = moment(savedDate, 'DD-MM-YYYY');
      const index = calendarData.findIndex((item) => item.dispatchDate === formatDate(savedDateMoment));
      if (index !== -1) {
        setSelectedCardIndex(index + 1);
      }
    } else {
      setSelectedCardIndex(0);
    }
  }, [calendarData]);

  return (
    <div className="calendar-page">
      <div className="all-button-container">
        <div
          className="calendar-item all-button"
          style={{
            backgroundColor: selectedCardIndex === 0 ? '#495eca' : '#dbe1ff',
          }}
          onClick={handleAllCardClick}
        >
          <p>ALL</p>
        </div>
      </div>
      <div className="calendar-view-container">
        <div className="calendar-scrollable">
          {calendarData.map((item, index) => (
            <div
              key={index}
              className="calendar-item"
              style={{
                backgroundColor: selectedCardIndex === index + 1 ? '#FDE380' : 'transparent',
              }}
              onClick={() => {
                setSelectedCardIndex(index + 1);
                handleCalendarCardClick(item.dispatchDate, index + 1);
              }}
            >
              <p>{item.day}</p>
              <p>{item.date}</p>
            </div>
          ))}
        </div>
        <CalendarMonthIcon
          sx={{
            fontSize: { xs: '25px', sm: '50px', md: '55px' },
            marginLeft: { xs: '0px', sm: '10px' },
            marginRight: { xs: '0px', sm: '0px' },
            marginTop: { xs: '5px', sm: '5px' },
            cursor: 'pointer',
          }}
          onClick={(event) => {
            event.stopPropagation();
            setCalendarOpen(true);
          }}
        />
      </div>
      {calendarOpen && (
        <div ref={calendarPickerRef} className="calendar-picker-overlay">
          <DatePicker
            selected={currentDate}
            onChange={(date) => handleDateChange(date)}
            onClose={() => setCalendarOpen(false)}
            inline
          />
        </div>
      )}
    </div>
  );
};

export default CalendarView;
