import React, { useState } from 'react';
import './SignInpopup.css';
import OtpLessLoginUI from './OtpLessLoginUI';
import BottomSheet from '../ui/BottomSheet';
import { Height } from '@mui/icons-material';
import { toggleAuthWallOff, toggleAuthWallOn } from '../../redux/auth/auth.slice';
import { useDispatch } from 'react-redux';

const AuthWall = ({ isVisible }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(isVisible);

  React.useEffect(() => {
    setOpen(isVisible);
  }, [isVisible]);
  const closeHandler = (e) => {
    dispatch(toggleAuthWallOff());
  };
  return (
    <div className={`popup-container ${isVisible ? 'visible' : ''}`}>
      <BottomSheet open={open} setOpen={setOpen} closeHandler={closeHandler}>
        <OtpLessLoginUI />
      </BottomSheet>
    </div>
  );
};

export default AuthWall;
