import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { Button, Box, Typography } from '@mui/material';
import { BattingPartnershipTable, TopPerformersTable } from './MatchSummary';
import NoDataComponent from './NoDataComponent';
import OverDetails from './overdetails';
import CricketCard from './topcard';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement);

const Analysis = () => {
  const { RunsPerOver, isLive, rpo } = useSelector((state) => state.match);
  const [activeTab, setActiveTab] = useState(0);
  const handleButtonClick = (index) => {
    setActiveTab(index);
  };

  const ballbyballdetails = rpo?.ovballByBallDetails;
  const dataSource = isLive && ballbyballdetails ? ballbyballdetails : RunsPerOver;

  // Ensure dataSource is defined and is an array
  const validDataSource = Array.isArray(dataSource) ? dataSource : [];

  // Extract data for the first innings
  const firstInning = validDataSource.find((inning) => inning.inningId === '1') || null;
  const firstInningOvers = firstInning ? firstInning.overs : [];
  const label1 = firstInningOvers.map((over) => over.overNumber.toString());
  const Inning1 = firstInningOvers.map((over) => over.currRuns);
  const firstInningData = firstInningOvers.map((over) => over.runs);
  const firstInningWickets = firstInning ? firstInning.overs.map((over) => over.wickets) : [];
  const team1 = validDataSource[0]?.teamName;

  // Extract data for the second innings
  const secondInning = validDataSource.find((inning) => inning.inningId === '2') || null;
  const secondInningOvers = secondInning ? secondInning.overs : [];
  const label2 = secondInningOvers.map((over) => over.overNumber.toString());
  const Inning2 = secondInningOvers.map((over) => over.currRuns);
  const secondInningData = secondInningOvers.map((over) => over.runs);
  const secondInningWickets = secondInning ? secondInning.overs.map((over) => over.wickets) : [];
  const team2 = validDataSource[1]?.teamName;

  // Add buffer label for the second innings
  if (label2.length > 0) {
    const lastOverNumber = parseInt(label2[label2.length - 1], 10);
    label2.push((lastOverNumber + 1).toString());
  }

  const maxFirstInning = Math.max(...firstInningData, 0);
  const maxSecondInning = Math.max(...secondInningData, 0);
  const maxDatasetValue = Math.max(maxFirstInning, maxSecondInning);
  const yAxisMax = maxDatasetValue * 1.25;

  const drawWicketsPlugin = {
    id: 'drawWicketsPlugin',
    afterDatasetsDraw(chart) {
      const { ctx, data, scales } = chart;
      data.datasets[0].wickets.forEach((wickets, index) => {
        const x = scales.x.getPixelForValue(index);
        const y = scales.y.getPixelForValue(data.datasets[0].data[index]);
        for (let i = 0; i < wickets; i++) {
          ctx.beginPath();
          ctx.arc(x, y - i * 10 - 5, 5, 0, 2 * Math.PI);
          ctx.fillStyle = 'red';
          ctx.fill();
          ctx.closePath();
        }
      });
    },
  };

  const generatePointStyles = (wickets, color) => {
    return {
      pointBackgroundColor: wickets.map((wicket) => (wicket > 0 ? color : 'transparent')),
      pointRadius: wickets.map((wicket) => (wicket > 0 ? 5 : 0)),
    };
  };

  const firstInningStyles = generatePointStyles(firstInningWickets, '#0089ED');
  const secondInningStyles = generatePointStyles(secondInningWickets, '#E1057C');

  const runsPerOverData = {
    labels: label1,
    datasets: [
      {
        label: 'RUNS PER OVER',
        data: firstInningData,
        backgroundColor: '#5649DF',
        borderColor: '#5649DF',
        width: '343px',
        height: '284px',
        borderWidth: 5,
        borderRadius: 4,
        wickets: firstInningWickets,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 5,
        top: 5,
        bottom: 5,
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
          padding: 7,
          boxWidth: 20,
          boxHeight: 40,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += `: ${context.raw} Runs`;
            }
            return label;
          },
        },
      },

      topRightTextPlugin: {
        id: 'topRightText',
        beforeDraw(chart) {
          const {
            ctx,
            chartArea: { top, right },
          } = chart;
          ctx.save();
          ctx.font = 'bold 12px Arial';
          ctx.fillStyle = '#000';
          const text = 'WICKET';
          const textWidth = ctx.measureText(text).width;
          const dotRadius = 5;

          // Shift text upwards
          ctx.fillText(text, right - textWidth - dotRadius - 0, top - 20);

          ctx.beginPath();
          ctx.arc(right - dotRadius - 55, top - 20, dotRadius, 0, 2 * Math.PI);
          ctx.fillStyle = 'red';
          ctx.fill();
          ctx.closePath();
          ctx.restore();
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Overs',
        },
        barPercentage: 1,
        categoryPercentage: 1,
        ticks: {
          maxRotation: 0,
        },
        grid: {
          borderDash: [5, 5],
        },
      },
      y: {
        title: {
          display: true,
          text: 'Runs',
          position: 'bottom',
        },
        max: yAxisMax,
        grid: {
          borderDash: [5, 5],
        },
      },
    },
  };

  const formatTeamName = (team) => {
    if (team) {
      return team.length > 12 ? `${team.substring(0, 12)}...` : team.toUpperCase();
    }
    return 'TEAM';
  };

  const wormChartData = {
    labels: label2,
    datasets: [
      {
        label: formatTeamName(team1),
        data: Inning1,
        backgroundColor: '#0089ED',
        borderColor: '#0089ED',
        borderWidth: 2,
        tension: 0.6,
        fill: false,
        spanGaps: true,
        pointRadius: firstInningStyles.pointRadius,
        pointHoverRadius: firstInningStyles.pointRadius,
        pointBackgroundColor: firstInningStyles.pointBackgroundColor,
      },
      {
        label: formatTeamName(team2),
        data: Inning2,
        backgroundColor: '#E1057C',
        borderColor: '#E1057C',
        borderWidth: 2,
        tension: 0.6,
        fill: false,
        spanGaps: true,
        pointRadius: secondInningStyles.pointRadius,
        pointHoverRadius: secondInningStyles.pointRadius,
        pointBackgroundColor: secondInningStyles.pointBackgroundColor,
      },
    ],
  };

  const wormChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 15,
        bottom: 5,
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'line',
          padding: 10,
          boxWidth: 20,
          boxHeight: 40,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Overs',
        },
      },
    },
  };

  const customLegendPlugin = {
    id: 'customLegend',
    beforeDraw(chart) {
      const {
        ctx,
        chartArea: { top, right, left },
        legend: { bottom },
      } = chart;
      const legendX = right - 100;
      const legendY = bottom - 34;

      ctx.save();

      // Draw blue dot
      ctx.fillStyle = '#0089ED';
      ctx.beginPath();
      ctx.arc(legendX, legendY, 5, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();

      // Draw red dot
      ctx.fillStyle = '#E1057C';
      ctx.beginPath();
      ctx.arc(legendX + 20, legendY, 5, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();

      // Draw text
      ctx.fillStyle = '#000';
      ctx.font = '10px Arial';
      ctx.fillText('WICKET', legendX + 45, legendY + 0);

      ctx.restore();
      // Draw custom text on top left
      ctx.save();
      ctx.fillStyle = '#000';
      ctx.font = '13px';
      ctx.fillText('WORM CHART', left - 5, top - 30);
      ctx.font = '12px';
      ctx.fillText('Runs', left - 5, top - 15);
      ctx.restore();
    },
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        marginBottom={1.5}
        sx={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          paddingX: 1,
        }}
      >
        <Button
          onClick={() => handleButtonClick(0)}
          sx={{
            backgroundColor: activeTab === 0 ? 'black' : '#F3F3F3',
            color: activeTab === 0 ? 'white' : 'black',
            borderRadius: 2,
            minWidth: '150px',
            marginX: 1,
            '&:hover': {
              backgroundColor: activeTab === 0 ? 'black' : '#F3F3F3',
            },
          }}
        >
          <Typography
            sx={{
              color: activeTab === 0 ? '#ffffff' : '#000000',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 500,
              fontSize: '13px',
              lineHeight: '16px',
              textTransform: 'none',
            }}
          >
            1st Inning
          </Typography>
        </Button>
        <Button
          onClick={() => handleButtonClick(1)}
          sx={{
            backgroundColor: activeTab === 1 ? 'black' : '#F3F3F3',
            color: activeTab === 1 ? 'white' : 'black',
            borderRadius: 2,
            minWidth: '150px',
            marginX: 1,
            '&:hover': {
              backgroundColor: activeTab === 1 ? 'black' : '#F3F3F3',
            },
          }}
        >
          <Typography
            sx={{
              color: activeTab === 1 ? '#ffffff' : '#000000',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 500,
              fontSize: '13px',
              lineHeight: '16px',
              textTransform: 'none',
            }}
          >
            2nd Inning
          </Typography>
        </Button>
      </Box>
      <Box>
        {activeTab === 0 && (
          <>
            {team1 ? (
              <>
                <Box>
                  <CricketCard inning={firstInning} />
                </Box>
                <Box
                  sx={{
                    height: '284px',
                    gap: 0,
                    borderRadius: '15px',
                    border: '1px solid #FFFFFF',
                    borderImageSource: 'linear-gradient(0deg, #F6F4FE, #F6F4FE), linear-gradient(0deg, #C8CDF9, #C8CDF9)',
                    borderImageSlice: 1,
                    boxShadow: '0px 6px 16px 0px #14142B1C',
                    margin: '16px',
                  }}
                >
                  <Bar
                    data={runsPerOverData}
                    options={options}
                    plugins={[drawWicketsPlugin, options.plugins.topRightTextPlugin]}
                  />
                </Box>
                <Box>
                  <BattingPartnershipTable inningId={1} />
                </Box>
                {!isLive && (
                  <Box>
                    <TopPerformersTable inningId={1} />
                  </Box>
                )}
                <Box>
                  <OverDetails inningId={1} />
                </Box>
              </>
            ) : (
              <NoDataComponent label="Analytics will be updated once the 1st inning starts" />
            )}
          </>
        )}
        {activeTab === 1 && (
          <>
            {team2 ? (
              <>
                <Box>
                  <CricketCard inning={secondInning} />
                </Box>
                <Box
                  sx={{
                    height: '284px',
                    gap: 0,
                    borderRadius: '15px',
                    border: '1px solid #FFFFFF',
                    borderImageSource: 'linear-gradient(0deg, #F6F4FE, #F6F4FE), linear-gradient(0deg, #C8CDF9, #C8CDF9)',
                    borderImageSlice: 1,
                    boxShadow: '0px 6px 16px 0px #14142B1C',
                    margin: '16px',
                  }}
                >
                  <Line data={wormChartData} options={wormChartOptions} plugins={[customLegendPlugin]} />
                </Box>
                <Box>
                  <BattingPartnershipTable inningId={2} />
                </Box>
                {!isLive && (
                  <Box>
                    <TopPerformersTable inningId={2} />
                  </Box>
                )}
                <Box>
                  <OverDetails inningId={2} />
                </Box>
              </>
            ) : (
              <NoDataComponent label="Analytics will be updated once the 2nd inning starts" />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Analysis;
