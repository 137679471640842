// api.js
import axios from 'axios';
import Cookies from 'js-cookie';
import store from '../redux/store';
import { setError } from '../redux/auth/auth.slice';
import { logout } from '../redux/auth/auth.action';
import { getUserIdentifier } from '../helpers/userUuid';
// Create an Axios instance with the base URL from environment variables
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Request Interceptor: Adds Authorization header to every request
api.interceptors.request.use(
  (config) => {
    const token = Cookies.get('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor: Handles 401 errors and attempts token refresh
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error is 401 and the request hasn't been retried yet
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = Cookies.get('refreshToken');

      if (refreshToken) {
        try {
          // Attempt to refresh the access token
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}api/v0/user/refresh-access-token`,
            { deviceId: getUserIdentifier() },
            { headers: { Authorization: `Bearer ${refreshToken}` } }
          );
          const newAccessToken = response.data.data.accessToken;

          // Store the new access token in cookies
          Cookies.set('accessToken', newAccessToken, {
            httpOnly: false,
            secure: true,
            sameSite: 'Strict',
            expires: 1,
          });

          // Update the Authorization header and retry the original request
          api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          // If token refresh fails, dispatch logout
          store.dispatch(logout());
          return Promise.reject(refreshError);
        }
      } else {
        // If no refresh token is available, dispatch logout
        store.dispatch(logout());
      }
    }

    // If the error is not 401 or token refresh fails, dispatch setError
    store.dispatch(setError(error?.response?.data?.message || error.message));
    return Promise.reject(error);
  }
);

export default api;
