import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default function CustomTabs({ activeTab, onTabChange, tabs }) {
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      width="100%"
      height="100%"
      marginBottom={1}
      marginTop={0}
      sx={{
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        paddingX: 0.5,
        backgroundColor: 'black',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
      }}
    >
      {tabs.map(
        (tab) =>
          tab.isVisible && (
            <Button
              key={tab.value}
              sx={{
                flex: {
                  xs: '0.5 0.5 auto',
                  md: '1',
                },
                backgroundColor: 'black',
                color: activeTab === tab.value ? '#FFCB05' : 'white',
                fontSize: '13px',
                borderBottom: '3px solid transparent',
                borderColor: activeTab === tab.value ? '#FFCB05' : 'transparent',
                borderRadius: '8px 8px 0 0',
                '&:hover': {
                  backgroundColor: activeTab === tab.value ? 'black' : 'black',
                },
              }}
              onClick={() => onTabChange(tab.value)}
            >
              {tab.label}
            </Button>
          )
      )}
    </Box>
  );
}
