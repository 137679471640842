import React from 'react';
import { List, Paper } from '@mui/material';
import VideoListItem from './VideoListItem';
import { useSelector } from 'react-redux';
import './css/videoList.css';

const VideoList = () => {
  const videoData = useSelector((state) => state.video);
  const highlightClips = videoData?.individualHiglightClipsList;
  const summaryVideo = videoData?.summaryAndFullMatchVideosList?.find((video) => video.videoCategory === 'HIGLIGHT_SUMMARY');
  const fullMatchVideos = videoData?.summaryAndFullMatchVideosList?.filter((video) => video.videoCategory === 'FULL_MATCH_VIDEO');

  return (
    <div className="video-list-component-container">
      <Paper className="video-list-container">
        <List component={Paper}>
          {summaryVideo && (
            <VideoListItem
              clip={summaryVideo}
              isPlaying={
                videoData.selectedSummaryOrFullMatchVideo?.mediaUrl === summaryVideo?.mediaUrl &&
                videoData?.currentPlayingVideo?.category === summaryVideo?.videoCategory
              }
            />
          )}
          {highlightClips &&
            highlightClips.map((clip, index) => (
              <VideoListItem
                key={index}
                clip={clip}
                isPlaying={
                  videoData.selectedIndividualHighlightClip?.clipS3Url === clip?.clipS3Url &&
                  videoData?.currentPlayingVideo?.category === videoData?.selectedIndividualHighlightClip?.videoCategory
                }
              />
            ))}
          {fullMatchVideos &&
            fullMatchVideos?.length > 0 &&
            fullMatchVideos.map((clip, index) => (
              <VideoListItem
                key={index}
                clip={clip}
                isPlaying={
                  videoData.selectedSummaryOrFullMatchVideo?.mediaUrl === clip?.mediaUrl &&
                  videoData?.currentPlayingVideo?.category === clip?.videoCategory
                }
              />
            ))}
        </List>
      </Paper>
    </div>
  );
};

export default VideoList;
