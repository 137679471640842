import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { MVPCard, TopPerformerCard, BowlerCard } from './Cards/TopPerformerCard';
import MainHeading from './Headings/MainHeading';
import GoldBadge from './../assets/Badge.svg';
import NoDataComponent from './NoDataComponent';
import ballIcon from './../assets/ball1.png';
import batIcon from './../assets/bat.png';
import player from './../assets/player.png';
import useTournamentStats from './tournamentstats';

const Awards = () => {
  const { mvp, tournamentId } = useSelector((state) => state.match);
  const matchData = useSelector((state) => state.match);
  const { tournamentStats } = useTournamentStats(tournamentId);

  if (!mvp) {
    return <NoDataComponent label="AWARDS WILL BE UPDATED AT THE END OF THE MATCH" />;
  }

  const over = matchData?.data?.[0]?.info?.[0]?.numOfBallsPerOver;

  const { playerName, profile, teamName, batsmanRun, playerSR, bowlerWicket, bowlerBall, bowlerRun, totalScore } = mvp;

  const playerER = bowlerBall === 0 ? bowlerRun : ((bowlerRun / bowlerBall) * over).toFixed(2);

  return (
    <Box sx={{ mt: 1.5 }}>
      <Box
        sx={{
          marginTop: 1.5,
          textAlign: 'center',
          position: 'relative',
          marginBottom: '1.5px',
        }}
      >
        <span
          style={{
            position: 'relative',
            color: '#5B5757',
            zIndex: '1',
            fontWeight: '600',
            backgroundColor: '#fff',
            fontSize: '12px',
            paddingLeft: '10px',
            paddingRight: '10px',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <img src={player} alt="Bat" style={{ width: '14px', height: '14px', marginRight: '4px' }} />
          PLAYER OF THE MATCH
        </span>
        <span
          style={{
            position: 'absolute',
            left: '16px',
            right: '16px',
            top: '50%',
            height: '1px',
            backgroundColor: '#5B5757',
            zIndex: '0',
          }}
        />
      </Box>
      <MainHeading title="MVP" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          borderRadius: '15px 15px 15px 15px',
          boxShadow: '0px 6px 16px 0px #14142B1C',
          margin: '8px  16px 16px 16px',
        }}
      >
        <MVPCard
          badgeIcon={GoldBadge}
          badgeText="PLAYER OF THE MATCH"
          profilePhoto={profile?.profilePicture}
          playerName={playerName}
          teamName={teamName}
          Points={totalScore}
          runs={batsmanRun}
          strikeRate={playerSR}
          wickets={bowlerWicket}
          economy={playerER}
        />
      </Box>
      {tournamentStats && (
        <>
          <Box
            sx={{
              marginTop: 1.5,
              textAlign: 'center',
              position: 'relative',
              marginBottom: '1.5px',
            }}
          >
            <span
              style={{
                position: 'relative',
                color: '#5B5757',
                zIndex: '1',
                fontWeight: '600',
                backgroundColor: '#fff',
                fontSize: '12px',
                paddingLeft: '10px',
                paddingRight: '10px',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              <img src={batIcon} alt="Bat" style={{ width: '17px', height: '17px', marginRight: '4px' }} />
              BEST BATTER
            </span>
            <span
              style={{
                position: 'absolute',
                left: '16px',
                right: '16px',
                top: '50%',
                height: '1px',
                backgroundColor: '#5B5757',
                zIndex: '0',
              }}
            />
          </Box>
          <MainHeading title="BEST BATTER" />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              borderRadius: '15px 15px 15px 15px',
              boxShadow: '0px 6px 16px 0px #14142B1C',
              margin: '8px  16px 16px 16px',
            }}
          >
            {Array.isArray(tournamentStats.bestBatsmans) &&
              tournamentStats.bestBatsmans.map((player) => (
                <TopPerformerCard
                  key={player.playerId}
                  badgeIcon={GoldBadge}
                  badgeText="BEST BATTER"
                  playerName={player.playerName}
                  runs={player.totalRuns}
                  wickets={player.totalWickets}
                  strikeRate={player.strikeRate}
                  economyRate={player.economyRate}
                  fours={player.fours}
                  sixes={player.sixes}
                  matchesPlayed={player.matchesPlayed}
                  matchesWon={player.matchesWon}
                  teamName={player.teamName}
                  profilePhoto={player?.profile?.profilePicture}
                  Points={player?.totalScore}
                />
              ))}
          </Box>
          <Box
            sx={{
              marginTop: 1.5,
              textAlign: 'center',
              position: 'relative',
              marginBottom: '1.5px',
            }}
          >
            <span
              style={{
                position: 'relative',
                color: '#5B5757',
                zIndex: '1',
                fontWeight: '600',
                backgroundColor: '#fff',
                fontSize: '12px',
                paddingLeft: '10px',
                paddingRight: '10px',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              <img src={ballIcon} alt="Ball" style={{ width: '15px', height: '15px', marginRight: '4px' }} />
              BEST BOWLER
            </span>
            <span
              style={{
                position: 'absolute',
                left: '16px',
                right: '16px',
                top: '50%',
                height: '1px',
                backgroundColor: '#5B5757',
                zIndex: '0',
              }}
            />
          </Box>
          <MainHeading title="BEST BOWLER" />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              borderRadius: '15px 15px 15px 15px',
              boxShadow: '0px 6px 16px 0px #14142B1C',
              margin: '8px 16px 100px 16px',
            }}
          >
            {Array.isArray(tournamentStats.bestBowlers) &&
              tournamentStats.bestBowlers.map((player) => {
                const overs = (player.ballsBowled / player.numOfBallsPerOver).toFixed(1);
                return (
                  <BowlerCard
                    key={player.playerId}
                    badgeIcon={GoldBadge}
                    badgeText="BEST BOWLER"
                    playerName={player.playerName}
                    Wickets={player.totalWickets}
                    strikeRate={player.bowlingStrikeRate}
                    economyRate={player.economyRate}
                    over={overs}
                    fours={player.fours}
                    sixes={player.sixes}
                    dotballs={player.dotBalls}
                    matchesPlayed={player.matchesPlayed}
                    matchesWon={player.matchesWon}
                    teamName={player.teamName}
                    profilePhoto={player?.profile?.profilePicture}
                    Points={player?.totalScore}
                  />
                );
              })}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Awards;
