import { createSlice } from '@reduxjs/toolkit'

const videoSlice = createSlice({
  name: 'match',
  initialState: {
    individualHiglightClipsList: null,
    summaryAndFullMatchVideosList: [],
    selectedIndividualHighlightClip: null,
    selectedSummaryOrFullMatchVideo: null,
    currentPlayingVideo: null,
    loading: true,
    error: null,
  },
  reducers: {
    setIndividualHighlightClipsList: (state, action) => {
      state.individualHiglightClipsList = action.payload
      state.loading = false
      state.error = null
    },
    setSummaryAndFullMatchVideosList: (state, action) => {
      state.summaryAndFullMatchVideosList = action.payload
      state.loading = false
      state.error = null
    },
    setSelectedIndividualHighlightClip: (state, action) => {
      state.selectedIndividualHighlightClip = action.payload
      state.loading = false
      state.error = null
    },
    setSelectedSummaryOrFullMatchVideo: (state, action) => {
      state.selectedSummaryOrFullMatchVideo = action.payload
      state.loading = false
      state.error = null
    },
    setCurrentPlayingVideo: (state, action) => {
      state.currentPlayingVideo = action.payload
      state.loading = false
      state.error = null
    },
  },
})

export const {
  setSelectedIndividualHighlightClip,
  setSelectedSummaryOrFullMatchVideo,
  setIndividualHighlightClipsList,
  setSummaryAndFullMatchVideosList,
  setCurrentPlayingVideo,
} = videoSlice.actions
export default videoSlice.reducer
